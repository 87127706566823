// extracted by mini-css-extract-plugin
export var column = "BannerGoogleMaps__column__Xl9Ul";
export var flex = "BannerGoogleMaps__flex__fP4i8";
export var flexColumn = "BannerGoogleMaps__flexColumn__Z6E7L";
export var gap1 = "BannerGoogleMaps__gap1___LhFf";
export var gap2 = "BannerGoogleMaps__gap2__Z2SwO";
export var gap3 = "BannerGoogleMaps__gap3__WkzrO";
export var gap4 = "BannerGoogleMaps__gap4__M4Gwf";
export var gap5 = "BannerGoogleMaps__gap5__mA9MV";
export var heading = "BannerGoogleMaps__heading__klZXj";
export var mapElement = "BannerGoogleMaps__mapElement__YREYl";
export var row = "BannerGoogleMaps__row__MFbMD";
export var wrapper = "BannerGoogleMaps__wrapper__b57F7";