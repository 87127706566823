// extracted by mini-css-extract-plugin
export var arrow = "BannerGoogleMapsInfoBox__arrow__O1B1J";
export var closeButton = "BannerGoogleMapsInfoBox__closeButton__Y3LzV";
export var column = "BannerGoogleMapsInfoBox__column__xbNVH";
export var flex = "BannerGoogleMapsInfoBox__flex__cLgz_";
export var flexColumn = "BannerGoogleMapsInfoBox__flexColumn__QMYYV";
export var gap1 = "BannerGoogleMapsInfoBox__gap1__NfEZv";
export var gap2 = "BannerGoogleMapsInfoBox__gap2__9la0Q";
export var gap3 = "BannerGoogleMapsInfoBox__gap3__JB_AF";
export var gap4 = "BannerGoogleMapsInfoBox__gap4__J7hMV";
export var gap5 = "BannerGoogleMapsInfoBox__gap5__ZQKcs";
export var infoBox = "BannerGoogleMapsInfoBox__infoBox__Q0e13";
export var infoBoxProviderLocation = "BannerGoogleMapsInfoBox__infoBoxProviderLocation__ezrU4";
export var infoBoxProviderTitle = "BannerGoogleMapsInfoBox__infoBoxProviderTitle__mrrZP";
export var providerImage = "BannerGoogleMapsInfoBox__providerImage__WIibp";
export var row = "BannerGoogleMapsInfoBox__row__oczdg";
export var topRow = "BannerGoogleMapsInfoBox__topRow__ShUTM";